export function getPathNameWithoutQuery(
  pathname: string,
  {
    project_id = undefined,
    team_id = undefined,
    invitation_id = undefined,
  }: {
    project_id?: string | null | undefined;
    team_id?: string | null | undefined;
    invitation_id?: string | null | undefined;
  },
) {
  const pathNameArray = pathname.split("/");
  const pathNameWithoutQuery = pathNameArray
    .map((v) => {
      if (v === project_id) {
        return "[project_id]";
      }
      if (v === team_id) {
        return "[team_id]";
      }
      if (v === invitation_id) {
        return "[invitation_id]";
      }
      return v;
    })
    .join("/");

  return pathNameWithoutQuery.replace(
    /^(\/project\/\[project_id\]\/(build|distribute|monitor)).*$/,
    "$1",
  );
}
