import { createTheme } from "@mui/material/styles";
import type { Shadows } from "@mui/material/styles/shadows";
import { components } from "./components";
import { palette } from "./palette";
import { typography } from "./typography";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    danger: true;
    "primary-green": true;
    modal: true;
    product: true;
  }
}

const theme = createTheme({
  palette,
  shadows: Array(25).fill("none") as Shadows,
  typography,
  components,
});

export default theme;
