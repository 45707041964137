import type { ReactElement } from "react";
import { type AccountMenuOptions, ProjectIcon, SettingsIcon } from "ui";
import { permissionList } from "./permissionList";
interface RoutesProps {
  [key: string]: string;
}

interface AccountMenuProps {
  href: string;
  label: string;
  icon: ReactElement;
  permittedRoles: string[];
}

const ROUTES: RoutesProps = {
  "/": "",
  "/profile/settings": "Profile settings",
  "/workspace/settings": "Workspace settings",
  "/workspace/settings/members": "Workspace settings",
  "/projects": "Projects",
  "/workspace/new": "New workspace",
  "/team/[team_id]/settings": "Team settings",
  "/project/[project_id]/settings": "Project settings",
  default: "/projects",
  newWorkspace: "/workspace/new",
};

const NAV_MENU_OPTIONS: AccountMenuProps[] = [
  {
    href: "/projects",
    label: "Projects",
    icon: <ProjectIcon />,
    permittedRoles: ["owner", "admin", "member"],
  },
  {
    href: "/workspace/settings",
    label: "Settings",
    icon: <SettingsIcon />,
    permittedRoles: ["owner", "admin"],
  },
];

const ACCOUNT_MENU_OPTIONS: AccountMenuOptions[] = [
  {
    href: "/profile/settings",
    label: "Profile settings",
    icon: "/settings-icon.svg",
    iconProps: {
      solidBackground: true,
    },
  },
  {
    label: "Logout",
    icon: "/logout-icon.svg",
    handler: "logout",
  },
];

const APP_PERMITTED_ROUTES: { [key: string]: string[] } = {
  // "/path": "required permission(s)",
  // "/workspace/invite": [
  //   permissionList.CREATE_ORGANIZATIONS,
  //   permissionList.INVITE_ORG_MEMBER,
  // ],
  "/workspace/new": [permissionList.CREATE_ORGANIZATIONS],
  "/workspace/settings": [permissionList.EDIT_ORGANIZATION],
  "/workspace/settings/members": [permissionList.EDIT_ORGANIZATION, permissionList.INVITE_ORG_MEMBER],
  "/projects": [],
  "/profile/settings": [],
  "/profile/setup": [],
  "/invitations/[invitation_token]/accept": [],
  "/team/new": [permissionList.CREATE_TEAM],
  "/team/[team_id]": [permissionList.LIST_PROJECTS],
  "/team/[team_id]/settings": [permissionList.EDIT_TEAM],
  "/team/[team_id]/settings/members": [permissionList.EDIT_TEAM, permissionList.LIST_TEAM_MEMBERS],
  "/team/[team_id]/projects": [permissionList.LIST_PROJECTS],
  "/team/[team_id]/projects/new": [permissionList.CREATE_PROJECT],
  "/project/[project_id]": [permissionList.RETRIEVE_PROJECT],
  "/project/[project_id]/build": [permissionList.RETRIEVE_PROJECT],
  "/project/[project_id]/distribute": [permissionList.RETRIEVE_PROJECT],
  "/project/[project_id]/manage": [permissionList.RETRIEVE_PROJECT],
  "/project/[project_id]/monitor": [permissionList.RETRIEVE_PROJECT],
  "/project/[project_id]/settings": [permissionList.EDIT_PROJECT],
  "/project/[project_id]/settings/members": [permissionList.EDIT_PROJECT, permissionList.LIST_PROJECT_MEMBERS],
  "/project/[project_id]/reporter": [permissionList.RETRIEVE_REPORT],
  "/project/[project_id]/reporter/[report_id]": [permissionList.RETRIEVE_REPORT],
};

export { ROUTES, ACCOUNT_MENU_OPTIONS, NAV_MENU_OPTIONS, APP_PERMITTED_ROUTES };
