import type { scopesTypes } from "./scopes";

export type RoleType = "owner" | "admin" | "member" | "user";
export type ReportRoleType = "editor" | "viewer";

export const options: {
  id: RoleType;
  role: "Owner" | "Admin" | "Member";
  subText: (scope: scopesTypes | string) => string;
}[] = [
  {
    id: "owner",
    role: "Owner",
    subText: () =>
      `Has complete oversight and control over the entire workspace, including all teams and projects within it.`,
  },
  {
    id: "admin",
    role: "Admin",
    subText: (scope) =>
      `Can change ${scope || "workspace"} settings and invite new members to the ${scope || "workspace"}.`,
  },
  {
    id: "member",
    role: "Member",
    subText: (scope) =>
      `Cannot change ${scope || "workspace"} settings or invite new members to the ${scope || "workspace"}.`,
  },
];

export const inviteOptions: {
  id: RoleType;
  role: "Admin" | "Member";
  subText: (scope: scopesTypes | string) => string;
}[] = [
  {
    id: "admin",
    role: "Admin",
    subText: (scope) =>
      `Can change ${scope || "workspace"} settings and invite new members to the ${scope || "workspace"}.`,
  },
  {
    id: "member",
    role: "Member",
    subText: (scope) =>
      `Cannot change ${scope || "workspace"} settings or invite new members to the ${scope || "workspace"}.`,
  },
];

export const shareOptions: {
  id: "editor" | "viewer";
  role: "Editor" | "Viewer";
  subText: string;
}[] = [
  {
    id: "editor",
    role: "Editor",
    subText: `Can edit report and share it with others.`,
  },
  {
    id: "viewer",
    role: "Viewer",
    subText: `Cannot edit report content nor share it with others.`,
  },
];
