import type { ThemeOptions } from "@mui/material";

export const components: ThemeOptions["components"] = {
  MuiModal: {
    styleOverrides: {
      root: {
        ".MuiBackdrop-root": {
          background: "rgba(41, 44, 87, 0.2) !important",
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        ".MuiTableRow-head": {
          th: {
            color: "#6a718e",
            fontWeight: "400",
          },
        },
        ".MuiTableCell-root": {
          fontSize: 15,
          borderColor: "#f2f2f5",
        },
        ".MuiTableCell-head": {
          padding: "7.5px 12px",
        },
        ".MuiOutlinedInput-root": {
          margin: 0,
        },
        ".MuiSelect-select": {
          width: "fit-content",
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        background: "white",
        // remove outline on focus
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        "> li": {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textAlign: "center",
        letterSpacing: "normal",
        lineHeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        borderRadius: "6px",
        borderStyle: "none",
        fontSize: "14px",
        transform: "rotate(360deg)",
        fontWeight: "500",
        textTransform: "none",
      },
    },
    variants: [
      {
        props: {
          variant: "contained",
        },
        style: {
          color: "common.white",
          height: "40px",
          padding: "14px 0",
          backgroundImage: "linear-gradient(to bottom, #7b86db, #6270c1)",
        },
      },
      {
        props: {
          variant: "contained",
          disabled: true,
        },
        style: {
          backgroundImage: "linear-gradient(to top, #cfd4ec, #e0e2f6)",
          color: "white !important",
        },
      },
      {
        props: {
          variant: "danger",
        },
        style: {
          color: "#d86b61",
          height: "40px",
          width: "140px",
          padding: "9px 0",
          border: "solid 1px #f0d0cd",
          backgroundColor: "#fef5f4",
          "&:hover": {
            backgroundColor: "#f0d0cd",
          },
        },
      },
      {
        props: {
          variant: "primary-green",
        },
        style: {
          color: "white",
          fontWeight: "normal",
          fontSize: "14px",
          backgroundImage: "linear-gradient(to bottom, #8bc8a8, #6fb28c)",
          "&:hover": {
            backgroundImage: "linear-gradient(to bottom, #8bc8a8, #8bc8a8)",
          },
          "&.Mui-disabled": {
            color: "#fff",
            backgroundColor: "#dbeee4",
            backgroundImage: "none",
          },
        },
      },
      {
        props: {
          variant: "outlined",
        },
        style: {
          color: "#1b1e3d",
          padding: "9px 0",
          border: "solid 1px #ebebf0",
          backgroundColor: "#ffffff",
          "&:hover": {
            backgroundColor: "#f9f9fa",
            border: "solid 1px #f2f2f4",
          },
        },
      },
      {
        props: {
          variant: "product",
        },
        style: {
          color: "#1b1e3d",
          backgroundImage: "linear-gradient(to top, #e9eaf4, #f4f4fa)",
          "&:hover": {
            backgroundColor: "#f9f9fa",
          },
        },
      },
    ],
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        ".MuiInputBase-root": {
          //   marginTop: "8px !important",
          //   marginBottom: "8px",
          borderRadius: "6px",
          border: "solid 1px #ebebf0",
          backgroundColor: "#fcfcfd",
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        // marginTop: "8px",
        // marginBottom: "8px",
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        height: "40px",
        // marginTop: "8px !important",
        // marginBottom: "8px",
        borderRadius: "6px",
        padding: "0 14px",
        border: "solid 1px #ebebf0",
        backgroundColor: "#fcfcfd",
        "&.Mui-error": {
          color: "#d86b61",
          border: "solid 1px #ebc3c0",
          backgroundColor: "#fef5f4",
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        fontSize: "14px",
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: "#ebebf0",
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: "0px",
        marginRight: "0px",
        fontSize: "13px",
        fontWeight: 300,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#6a718e",
      },
    },
  },
  MuiTabs: {
    defaultProps: {
      indicatorColor: "primary",
      textColor: "secondary",
    },
    styleOverrides: {
      root: {
        marginLeft: 10,
        marginRight: 10,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        textTransform: "none",
      },
    },
  },
  MuiCircularProgress: {
    defaultProps: {
      size: 20,
      thickness: 7,
      sx: {
        color: "common.white",
      },
    },
  },
  MuiAlert: {
    variants: [
      {
        props: {
          severity: "error",
        },
        style: {
          borderRadius: "6px",
          border: "solid 1px #ebc3c0",
          color: "#d86b61",
          backgroundColor: "#fef5f4",
        },
      },
      {
        props: {
          severity: "success",
        },
        style: {
          borderRadius: "6px",
          border: "solid 1px #aee4c6",
          backgroundColor: "#f7fffb",
          color: "#599e78",
        },
      },
    ],
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        //     width: 36,
        //     height: 20,
        // padding: 0,
        margin: 0,
      },
      switchBase: {
        // padding: 1,
        "&$checked": {
          transform: "translateX(16px)",
          color: "#f00",
          "& + $track": {
            backgroundColor: "#00ff00",
            opacity: 1,
            border: "none",
          },
        },
        "&$focusVisible $thumb": {
          color: "#52d869",
          border: "6px solid #00f",
        },
      },
      thumb: {
        width: 20,
        height: 20,
      },
      track: {
        borderRadius: 20 / 2,
        border: "1px solid #ebebf0",
        backgroundColor: "#fcfcfd",
        opacity: 1,
        transition: "background-color 0.2s",
      },
      //   checked: {},
    },
  },
  // SLider
  MuiSlider: {
    styleOverrides: {
      root: {
        color: "#ebebf0",
        height: "4px",
        ".MuiSlider-thumbColorPrimary, .Mui-active": {
          boxShadow: "none !important",
          "&:hover, &:focus": {
            boxShadow: "none !important",
          },
        },
      },
      // set the MuiSlider-track height to 4px
      track: {
        height: "4px",
        border: "none",
      },
      // set color to progress bar
      rail: {
        opacity: 1,
      },
      thumb: {
        height: "16px",
        width: "16px",
        backgroundImage: "linear-gradient(to bottom, #434866, #1b253d)",
      },
    },
  },
};
