export type scopesTypes = "organization" | "team" | "project" | "user" | "report";
export type publicScopesTypes = "workspace" | "team" | "project" | "user" | "report";

export const scopes: {
  [key: string]: scopesTypes;
} = {
  org: "organization",
  team: "team",
  project: "project",
  report: "report",
  user: "user",
};
